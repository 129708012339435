import React, { useEffect } from 'react';
import './sass/styles.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Loading from './components/Loader';

import Home from './views/Home';
// import About from "./views/Home";
// import Vision from "./views/Home";
// import Technology from "./views/Home";
// import Team from "./views/Home";
// import Contact from "./views/Home";
import JoinUs from './views/JoinUs';

const App = () => {
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    handleLoading();
  }, []);

  const handleLoading = () => {
    setTimeout(() => {
      console.log('LOADDED');
      setLoading(false);
    }, 1000);
  };

  return (
    <>
      {!loading ? (
        <Router>
          <Switch>
            <Route path='/joinus'>
              <JoinUs />
            </Route>
            <Route exact path='/:id?'>
              <Home />
            </Route>
            {/* <Route path="/about">
          <About />
        </Route>
        <Route path="/vision">
          <Vision />
        </Route>
        <Route path="/technology">
          <Technology />
        </Route>
        <Route path="/team">
          <Team />
        </Route> */}
            {/* <Route path="/contact">
          <Contact />
        </Route> */}
          </Switch>
        </Router>
      ) : (
        <Loading />
      )}
    </>
  );
};
export default App;
