import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';

// React-scroll
import * as Scroll from 'react-scroll';
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll';

// React Bootstrap
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

// Logo
import Logo from '../assets/img/Flexboks_logo.png';

const NavBar = () => {
  const history = useHistory();
  return (
    <>
      <Navbar
        fixed='top'
        className='navbar'
        bg='background'
        variant='dark'
        expand='lg'>
        <Navbar.Brand href='/'>
          <img className='logo' src={Logo} alt='logo' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='navbar-position ml-auto'>
            <div
              className='nav_link'
              to='home'
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={() => {
                history.push('home');
                Scroll.scroller.scrollTo('home');
              }}>
              Home
            </div>

            <div
              className='nav_link'
              to='about'
              // spy={true}
              // smooth={true}
              // offset={-70}
              // duration={500}
              onClick={() => {
                history.push('about');
                Scroll.scroller.scrollTo('about');
              }}>
              About us
            </div>
            <div
              className='nav_link'
              to='vision'
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={() => {
                history.push('vision');
                Scroll.scroller.scrollTo('vision');
              }}>
              Our vision
            </div>
            <div
              className='nav_link'
              to='tech'
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={() => {
                history.push('tech');
                Scroll.scroller.scrollTo('tech');
              }}>
              Technology
            </div>
            {/* <div
              className="nav_link"
              to="team"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={() => {
                history.push("team");
                Scroll.scroller.scrollTo("team");
              }}
            >
              Team
            </div> */}
            <NavLink
              className='nav_link'
              to='joinus'
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}>
              Join Us
            </NavLink>
            <div
              className='nav_link'
              to='contact'
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={() => {
                history.push('contact');
                Scroll.scroller.scrollTo('contact');
              }}>
              Contact
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavBar;
