import React from "react";

import Spinner from "../assets/img/Flexboks_element_2.png";

const Loading = (props) => {
  return (
    <>
      <div className="loading_background">
        <img class="spinner" src={Spinner} />
      </div>
    </>
  );
};

export default Loading;
