import React, { useState } from 'react';

import { send } from 'emailjs-com';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// React Scroll
import { Element } from 'react-scroll';

const ContactForm = (props) => {
  const [formData, setFormData] = useState({
    from_name: '',
    reply_to: '',
    message: '',
  });

  const [formError, setFormError] = useState();
  const [formSuccess, setFormSuccess] = useState();

  function validateEmail(email) {
    const regEX = /\S+@\S+\.\S+/;
    const patternMatches = regEX.test(email);
    return patternMatches;
  }

  const validateForm = () => {
    if (formData.from_name.length === 0) {
      setFormError('Please fill in your name');
      setTimeout(() => {
        setFormError(null);
      }, 5000);
      return false;
    } else if (!validateEmail(formData.reply_to)) {
      setFormError('Please fill in a valid email address');
      setTimeout(() => {
        setFormError(null);
      }, 5000);
      return false;
    } else if (formData.message.length === 0) {
      setFormError('Textarea cannot be empty');
      setTimeout(() => {
        setFormError(null);
      }, 5000);
      return false;
    } else {
      return true;
    }
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      send(
        'service_z0czgkq',
        'template_ak17hok',
        formData,
        'user_Lccq22XPOoGWQkGaV6ZZW'
      )
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          setFormSuccess('Your message has successfully been sendt!');
          setFormData({ from_name: '', reply_to: '', message: '' });
          setTimeout(() => {
            setFormSuccess(null);
          }, 5000);
        })
        .catch((err) => {
          console.log('FAILED...', err);
        });
    }
  };

  return (
    <>
      <Element name='contact'></Element>

      <Container className='contact-container'>
        <Row className='justify-content-md-center'>
          <Col className='form-col' md='auto'>
            <h2>Contact us</h2>
            <h3>Want to join our team?</h3>
            {formError && <p className='setFormError'>{formError}</p>}
            {formSuccess && <p className='setFormSuccess'>{formSuccess}</p>}
            <form onSubmit={onSubmit}>
              <input
                className='form_input'
                type='text'
                id='name'
                name='from_name'
                placeholder='Name'
                value={formData.from_name}
                onChange={handleChange}></input>
              <input
                className='form_input'
                type='text'
                id='email'
                name='reply_to'
                placeholder='Email'
                value={formData.reply_to}
                onChange={handleChange}></input>
              <textarea
                className='form_input'
                type='text'
                id='message'
                name='message'
                value={formData.message}
                onChange={handleChange}></textarea>
              <button className='form-button' type='submit' value='Submit'>
                Send message
              </button>{' '}
            </form>
          </Col>
          <Col className='contact-col' md='auto'>
            <h2>Contact us</h2>
            <p>
              post@flexboks.com <br />+ 47 99357299
            </p>
            <div className='location'>
              <h2>Location</h2>
              <p>
                Petter Dass gate 3, <br />
                8656 Mosjøen{' '}
              </p>
            </div>
            <div className='business'>
              <h2>Business hours</h2>
              <p>Mon - Fri 08 - 16</p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactForm;
