import React from "react";

// React Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { Element } from "react-scroll";

const JoinTeam = (props) => {
  return (
    <>
      {/* <Element name="joinus"></Element> */}

      <Container className="vacancies-container1">
        <Row>
          <Col xs={11} md={7} className="vacancies-col">
            <h2>Flexboks søker utviklere</h2>
            <p>
              Flexboks AS ønsker seg hyggelige kollegaer til å ta del i vårt
              voksende teknologimiljø. Flexboks er et nyetablert Tech selskap
              som utvikler egne tjenester, samt innovative løsninger for ulike
              aktører med fokus på bærekraft. Vi består i dag av et team på 8
              personer med nye og moderne lokaler på Kompetansehuset i Mosjøen.
              Vår ambisjon er å skape Nord-Norges største teknologi og
              innovasjonsmiljø. For å oppnå dette ønsker vi å styrke vårt team
              med dyktige unge utviklere.
            </p>
          </Col>
        </Row>
      </Container>
      <Container className="vacancies-container ">
        <Row>
          <Col xs={11} md={6} className="vacancies-col">
            <div>
              <h3>Arbeidsoppgaver</h3>
              <ul>
                <li>Programvareutvikling, Back-End og Front-End</li>
                <li>Databasehåndtering</li>
                <li>App utvikling </li>
                <li>
                  Holde deg oppdatert på ny teknologi og nye trender i markedet
                </li>
                <li>Videreutvikle Flexboks eksiterende tjenester</li>
              </ul>
            </div>
          </Col>
          <Col xs={11} md={6} className="vacancies-col">
            <div>
              <h3>Erfaring og kompetanse </h3>
              <ul>
                <li>Språk: Python, JavaScript </li>
                <li>Erfaring med Web oppsett</li>
                <li>Front-End: HTML, CSS/SASS, React</li>
                <li>Back-End: Node.js, Express, Django, Flask</li>
                <li>Database: Firebase, MySql</li>
                <li>React Native</li>
                <li>Github</li>
              </ul>
            </div>
          </Col>
          <Col xs={11} md={6} className="vacancies-col">
            <div>
              <h3>Personlige egenskaper</h3>
              <ul>
                <li>Du er selvdreven, effektiv og tar initiativ</li>
                <li>Gode samarbeidsevner</li>
                <li>
                  Interesse for teknologi, og synes det er spennende å jobbe i
                  et innovativt teknologimiljø i vekst.
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={11} md={6} className="vacancies-col">
            <div>
              <h3>Vi tilbyr</h3>
              <ul>
                <li>Et engasjert miljø med høyt tempo</li>
                <li>
                  Spennende utfordringer med store muligheter til å påvirke
                </li>
                <li>Varierte arbeidsoppgaver</li>
                <li>
                  Innovativt og hyggelig Tech miljø i moderne lokaler i Mosjøen
                </li>
                <li>Konkurransedyktige betingelser</li>
                <li>Fleksibilitet</li>
              </ul>
            </div>
          </Col>{" "}
        </Row>
      </Container>
      <Container className="vacancies-container">
        <Row>
          <Col xs={11} md={7} className="vacancies-col">
            <div>
              <p>
                Manglende erfaring kan kompenseres med rette personlige
                kvalifikasjoner, og vi vil kunne tilby opplæringsløp for
                personer hvor kjemien stemmer.
                <br />
                <br /> Ønsker personer som kan tiltre i 100% stilling.
                <br />
                <br /> Vi ønsker å høre fra deg
                <br />
                Ta kontakt for en hyggelig samtale.
              </p>
            </div>
            <div>
              <p>
                Fredrik Nicolaysen
                <br />
                Daglig Leder Flexboks AS
                <br />
                Tlf: 99357299 <br />
                @: fredrik@flexboks.com
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default JoinTeam;
