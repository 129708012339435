import img_0 from "./images/img_0.png";
import img_1 from "./images/img_1.png";
import img_2 from "./images/img_2.png";
import img_3 from "./images/img_3.png";
import img_4 from "./images/img_4.png";
import img_5 from "./images/img_5.png";
import img_6 from "./images/img_6.png";
import img_7 from "./images/img_7.png";
import img_8 from "./images/img_8.png";
import img_9 from "./images/img_9.png";
import img_10 from "./images/img_10.png";
import img_11 from "./images/img_11.png";

export default {
  v: "5.7.13",
  fr: 30,
  ip: 0,
  op: 600,
  w: 1920,
  h: 1080,
  nm: "Comp 1",
  ddd: 0,
  assets: [
    {
      id: "image_0",
      w: 1059,
      h: 1283,
      u: "",
      p: img_0,
      e: 0,
    },
    {
      id: "image_1",
      w: 1679,
      h: 1289,
      u: "",
      p: img_1,
      e: 0,
    },
    {
      id: "image_2",
      w: 644,
      h: 644,
      u: "",
      p: img_2,
      e: 0,
    },
    {
      id: "image_3",
      w: 1289,
      h: 1289,
      u: "",
      p: img_3,
      e: 0,
    },
    {
      id: "image_4",
      w: 162,
      h: 162,
      u: "",
      p: img_4,
      e: 0,
    },
    {
      id: "image_5",
      w: 60,
      h: 60,
      u: "",
      p: img_5,
      e: 0,
    },
    {
      id: "image_6",
      w: 126,
      h: 126,
      u: "",
      p: img_6,
      e: 0,
    },
    {
      id: "image_7",
      w: 78,
      h: 78,
      u: "",
      p: img_7,
      e: 0,
    },
    {
      id: "image_8",
      w: 101,
      h: 114,
      u: "",
      p: img_8,
      e: 0,
    },
    {
      id: "image_9",
      w: 166,
      h: 190,
      u: "",
      p: img_9,
      e: 0,
    },
    {
      id: "image_10",
      w: 4622,
      h: 3595,
      u: "",
      p: img_10,
      e: 0,
    },
    {
      id: "image_11",
      w: 395,
      h: 395,
      u: "",
      p: img_11,
      e: 0,
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: "2 p V",
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [45],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 300,
              s: [21],
            },
            { t: 599, s: [45] },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 300,
              s: [-6],
            },
            { t: 599, s: [0] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [-559.275, 297.391, 0],
              to: [6.833, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 300,
              s: [-518.275, 297.391, 0],
              to: [0, 0, 0],
              ti: [6.833, 0, 0],
            },
            { t: 599, s: [-559.275, 297.391, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [11.135, 643.472, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 0,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 300,
              s: [110, 110, 100],
            },
            { t: 599, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: "3 p H",
      refId: "image_1",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [45],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 300,
              s: [21],
            },
            { t: 599, s: [45] },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 300,
              s: [-6],
            },
            { t: 599, s: [0] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [1790.785, 987.289, 0],
              to: [-14.833, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 300,
              s: [1701.785, 987.289, 0],
              to: [0, 0, 0],
              ti: [-14.833, 0, 0],
            },
            { t: 599, s: [1790.785, 987.289, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [839.392, 644.113, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 0,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 300,
              s: [110, 110, 100],
            },
            { t: 599, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: "4medium runding",
      refId: "image_2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1522.22, -61.545, 0], ix: 2, l: 2 },
        a: { a: 0, k: [321.839, 321.839, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 0,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 299,
              s: [115, 115, 100],
            },
            { t: 599, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: "5big runding",
      refId: "image_3",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1015.27, 1228.165, 0], ix: 2, l: 2 },
        a: { a: 0, k: [644.468, 644.467, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 0,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 299,
              s: [90, 90, 100],
            },
            { t: 599, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: "5X v",
      refId: "image_4",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0],
            },
            { t: 599, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [331.921, 897.451, 0], ix: 2, l: 2 },
        a: { a: 0, k: [80.911, 80.91, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: "6X u",
      refId: "image_5",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0],
            },
            { t: 599, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [861.81, 265.865, 0], ix: 2, l: 2 },
        a: { a: 0, k: [29.639, 29.638, 0], ix: 1, l: 2 },
        s: { a: 0, k: [123, 123, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 2,
      nm: "7X n",
      refId: "image_6",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0],
            },
            { t: 599, s: [-360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [1128.168, 930.016, 0], ix: 2, l: 2 },
        a: { a: 0, k: [62.619, 62.619, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 2,
      nm: "8X h",
      refId: "image_7",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0],
            },
            { t: 599, s: [-360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [1609.859, 438.196, 0], ix: 2, l: 2 },
        a: { a: 0, k: [38.85, 38.85, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 2,
      nm: "9c v 4",
      refId: "image_8",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 450,
              s: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 486,
              s: [100],
            },
            { t: 525, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1093.985, 502.424, 0], ix: 2, l: 2 },
        a: { a: 0, k: [50.031, 56.529, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 450,
      op: 525,
      st: 450,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 2,
      nm: "9c v 3",
      refId: "image_8",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 300,
              s: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 336,
              s: [100],
            },
            { t: 375, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1096.985, 148.424, 0], ix: 2, l: 2 },
        a: { a: 0, k: [50.031, 56.529, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 300,
      op: 375,
      st: 300,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 2,
      nm: "9c v 2",
      refId: "image_8",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 150,
              s: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 186,
              s: [100],
            },
            { t: 225, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [856.985, 965.424, 0], ix: 2, l: 2 },
        a: { a: 0, k: [50.031, 56.529, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 150,
      op: 225,
      st: 150,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 2,
      nm: "9c v",
      refId: "image_8",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 36,
              s: [100],
            },
            { t: 75, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [524.985, 563.424, 0], ix: 2, l: 2 },
        a: { a: 0, k: [50.031, 56.529, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 75,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 2,
      nm: "10c h 4",
      refId: "image_9",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 525,
              s: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 561,
              s: [100],
            },
            { t: 600, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [601.335, 581.778, 0], ix: 2, l: 2 },
        a: { a: 0, k: [82.693, 94.587, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 525,
      op: 600,
      st: 525,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 2,
      nm: "10c h 3",
      refId: "image_9",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 375,
              s: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 411,
              s: [100],
            },
            { t: 450, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1692.335, 668.778, 0], ix: 2, l: 2 },
        a: { a: 0, k: [82.693, 94.587, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 375,
      op: 450,
      st: 375,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 16,
      ty: 2,
      nm: "10c h 2",
      refId: "image_9",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 225,
              s: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 261,
              s: [100],
            },
            { t: 300, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [223.335, 663.778, 0], ix: 2, l: 2 },
        a: { a: 0, k: [82.693, 94.587, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 225,
      op: 300,
      st: 225,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 17,
      ty: 2,
      nm: "10c h",
      refId: "image_9",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 75,
              s: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 111,
              s: [100],
            },
            { t: 150, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1249.335, 476.778, 0], ix: 2, l: 2 },
        a: { a: 0, k: [82.693, 94.587, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 75,
      op: 150,
      st: 75,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 18,
      ty: 2,
      nm: "11Prikker",
      refId: "image_10",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [1083.588, 525.386, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 300,
              s: [1309.588, 373.386, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 599, s: [1083.588, 525.386, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [2310.522, 1797.316, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 19,
      ty: 2,
      nm: "12runding",
      refId: "image_11",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [570.016, 252.45, 0], ix: 2, l: 2 },
        a: { a: 0, k: [197.236, 197.236, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 0,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 300,
              s: [120, 120, 100],
            },
            { t: 599, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
