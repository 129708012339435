import React from "react";

import Lottie from "react-lottie";
import animationData from "../assets/lotties/data_flexboksanim1";

const Animation = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    isClickToPauseDisabled: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="Lottie" style={{ pointerEvents: "none" }}>
      <Lottie
        options={defaultOptions}
        style={{ position: "fixed", zIndex: "1", marginTop: "70px" }}
      />
    </div>
  );
};

export default Animation;
