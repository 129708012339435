import React from "react";

// React Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Element } from "react-scroll";

// Images
import macbookImageLaks from "../assets/img/laptopLaks.png";
import macbookImageSDG from "../assets/img/laptopSDG.png";
import macbookImageAnalytics from "../assets/img/laptopAnalytics.png";
import macbookImageSmitte from "../assets/img/laptopSmitte.png";

const OurVision = (props) => {
  const [macImage, setMacImage] = React.useState(macbookImageLaks);
  const MacImg = [
    macbookImageLaks,
    macbookImageSDG,
    macbookImageAnalytics,
    macbookImageSmitte,
  ];
  React.useEffect(() => {
    setInterval(() => {
      setMacImage(MacImg[Math.floor(Math.random() * MacImg.length)]);
    }, 4000);
  }, []);
  return (
    <>
      <Element name="vision"></Element>
      <Container className="vision-container">
        <Row className="vision_row">
          <Col className="macbook_col">
            {" "}
            <img src={macImage} alt="macbook" className="macbook" />
          </Col>
          <Col className="vision_col" md={7}>
            <h2>Our vision</h2>
            <h3>To be the leading tech environment in northern norway</h3>
            <p>
              Flexboks’ main vision is to be a recognizable contributor to the
              digital revolution, and become one of the leading technological
              stakeholders in northern Norway. Creating an inspiring and
              creative workplace, where our employees can grow and learn, every
              day.
            </p>

            <h2>How to get there</h2>
            <h3>Inspiring innovative environment, quality and hard work</h3>
            <p>
              Our developers holds the highest competence on multiple
              programming languages and frameworks. By nurturing our common love
              for technology, with a positive and including environment, we make
              each other better. We work hard as a team to building high-end
              products for today and for the future.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OurVision;
