import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Lottie
import LottieAnimation from "../components/Lottie";
import home from "../assets/lotties/lottie.json";

// React Scroll
import { Element } from "react-scroll";
import { Link } from "react-scroll";

const Homepage = (props) => {
  return (
    <>
      <Element name="home"></Element>
      <Container>
        <Row>
          <Col>
            <div className="header">
              <div className="header-content">
                <h2>Pushing development</h2>
                <h1>Re-imagine technology</h1>
                <p>
                  Flexboks is a technology company that develops innovative
                  solutions to various clients with focus on sustainability and
                  quality.
                </p>
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  {" "}
                  <div className="button">Contact us</div>
                </Link>
              </div>
            </div>
          </Col>
          <div className="lottie_animation">
            <LottieAnimation lotti={home} height={300} width={300} />
          </div>
        </Row>
      </Container>
    </>
  );
};
export default Homepage;
