import React, { useEffect } from "react";
import NavBar from "../components/Navbar";
import Animation from "../components/Animation";
import JoinTeam from "../components/Vacancies";
import ContactForm from "../components/ContactForm";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

const JoinUs = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <>
        <NavBar />
        <Animation />

        <JoinTeam />

        <ContactForm />
      </>
    </>
  );
};

export default JoinUs;
