import React, { useEffect } from 'react';
import Homepage from '../components/Homepage';
import NavBar from '../components/Navbar';
import Animation from '../components/Animation';
import AboutUs from '../components/AboutUs';
import OurTeam from '../components/OurTeam';
import OurVision from '../components/OurVision';
import Tech from '../components/Tech';
import ContactForm from '../components/ContactForm';
import { useLocation, useParams } from 'react-router';
import * as Scroll from 'react-scroll';
import { Element } from 'react-scroll';

const Home = (props) => {
  const location = useLocation();
  const { id } = useParams();
  React.useEffect(() => {
    const scrollConfig = {
      duration: 1000,
      delay: 0,
      smooth: true,
      offsett: 0,
    };
    if (id === 'about') {
      Scroll.scroller.scrollTo('about', scrollConfig);
    } else if (id === 'home') {
      Scroll.scroller.scrollTo('home', scrollConfig);
    } else if (id === 'vision') {
      Scroll.scroller.scrollTo('vision', scrollConfig);
    } else if (id === 'tech') {
      Scroll.scroller.scrollTo('tech', scrollConfig);
    } else if (id === 'team') {
      Scroll.scroller.scrollTo('team', scrollConfig);
    } else if (id === 'contact') {
      Scroll.scroller.scrollTo('contact', scrollConfig);
    } else {
      window.scrollTo(0, 0);
    }
  }, [id]);

  return (
    <>
      <>
        <NavBar />
        <Animation />
        <Homepage />
        <AboutUs />
        <OurVision />
        <Tech />
        {/* <OurTeam /> */}
        <ContactForm />
      </>
    </>
  );
};

export default Home;
