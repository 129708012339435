import React from "react";

// React Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Image
import FlexboksLogo from "../assets/img/Flexboks_element_2.png";

// React Scroll
import { Element } from "react-scroll";

const AboutUs = (props) => {
  return (
    <>
      <Container className="about-container">
        <Element name="about"></Element>
        <Row>
          <Col className="about-col" xs={11} md={7}>
            <h2>Our history</h2>
            <p>
              Flexboks was founded in Mosjøen, Norway in 2020, with a vision to
              become one of the leading technology environments in northern
              Norway. Flexboks Is a collaboration between Fbn Media Group AS
              (Est 2012) and Helgeland Event AS (Est 2008), combining the
              technology expertise from FBN with the skills and engagement on
              marketing, sales and brainstorming from HEV. together we share the
              passion for developing new and innovative technologies,
              contributing against creating a more sustainable society.
            </p>
            <h2>What we do</h2>
            <p>
              Flexboks delivers various products within artificial intelligence,
              IoT, big data analytics and advanced web applications. We can help
              your business benefit from the use of technology, and be your
              preferred partner for implementing new technology in your daily
              business routine.
            </p>
          </Col>
          <Col className="flex-image-col" xs={12} md={4}>
            <img
              className="background-logo-image"
              src={FlexboksLogo}
              title="Flexboks logo"
              alt="Flexboks logo"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AboutUs;
