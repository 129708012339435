import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// React Scroll
import { Element } from "react-scroll";

const OurTech = (props) => {
  return (
    <>
      <Element name="tech"></Element>
      <Container className="tech-container">
        <Row>
          <Col className="tech-col" xs={11} md={7}>
            {" "}
            <h2>Technology</h2>
            <h3>Keeping up with the coding communities</h3>
            <p>
              Our developers are passionate about coding, and every day we
              strive to keep up to date with the top trending technologies. We
              possess great knowledge in both computer visualization and AI
              models, along with hands-on experience creating custom IOT-devices
              from scratch.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OurTech;
